import React from "react";

function Page3() {
  return (
    <div className="calender">
      <h1>Page3</h1>
    </div>
  );
}

export default Page3;
