import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.css";
import phone from "../assets/phone.png";
import pickusvideo from "../assets/pickus_video.mp4";
import ai from "../assets/ai.mp4";
import estimate01 from "../assets/estimate-01.mp4";
import estimate02 from "../assets/estimate-02.mp4";
import estimatemobile from "../assets/estimate-mobile.mp4";
import match01 from "../assets/match-01.mp4";
import match02 from "../assets/match-02.mp4";
import matchmobile from "../assets/match-mobile.mp4";
import shop01 from "../assets/shop-01.png";
import shop02 from "../assets/shop-02.png";
import shop03 from "../assets/shop-03.png";
import shop04 from "../assets/shop-04.png";
import shop05 from "../assets/shop-05.png";
import shop06 from "../assets/shop-06.png";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { useMediaQuery } from 'react-responsive'
function Service() {
  useEffect(() => {
    AOS.init();
  })
  
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1320px)'});
  const dataAos = 'fade-up';
  const dataAosDuration = '600';
  const dataAosOffset = '300';

  return (
    <div className="about" id="about">

      <section className="mainImg">
        <div className="content-components">
          <div className="mainText content-inner">
            <h2><span>지구를</span> 살리는</h2>
            <h3>중고가전 ∙ 가구 리사이클링</h3>
            <h4>#안전 #전문가 #간편 #환경</h4>
          </div>
        </div>
        <div className="arrow-container">
          <div className="arrow-down"></div>
        </div>
      </section>
      <section className="intro-pickus" id="about">
        <div className="content-components">
          <div className="content-inner">
            <h1>재활용센터 O2O 플랫폼 피커스는<br></br>
            낡은 것을 새롭게 보는 관점으로 더 나은 경제적 가치를 만들어요<br></br>
            사용자들이 언제나 간편하게 중고가전 ∙ 가구를 거래할 수 있도록 노력하고 있어요
            </h1>
          </div>
        </div>
      </section>
      <section className="info-app-pickus" id="about">
        <div className="content-components">
          <div className="content-inner phone-column">
            <div className="text"><h2>중고판매</h2>
            <h1>언제 어디서든 중고가전 ∙ 가구를<br></br>빠르게 처분할 수 있어요</h1></div>
            {isDesktopOrMobile !== true ?
              <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={estimate01}></source></video></div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={estimate02}></source></video></div>
              </div>
            : 
               <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={estimatemobile}></source></video></div>
              </div>
            }
            <div className="sub-text"><p>간편하게 물품 정보를 입력하면<br></br>전국 재활용센터가 매칭되어<br></br>실시간 최고가 견적을 비교할 수 있어요</p></div>
          </div>
        </div>
      </section>
      <section className="info-app-pickus" id="about">
        <div className="content-components">
          <div className="content-inner carousel-column">
            <h2>중고구매 - 파트너샵</h2>
            <h1>여기저기 복잡한 비교는 이제 그만<br></br>한변에 다양한 상품을 비교해요!</h1>
            <Flicking hideBeforeInit={true} firstPanelSize="50%"
            align="prev"
            circular={true}
            onMoveEnd={e => {
            }}>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop01}></img></div>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop02}></img></div>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop03}></img></div>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop04}></img></div>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop05}></img></div>
              <div className="panel" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={shop06}></img></div>
            </Flicking>
          </div>
        </div>
      </section>
      <section className="info-app-pickus" id="about">
        <div className="content-components">
          <div className="content-inner phone-column">
            <div className="text"><h2>중고구매 - 매칭</h2>
            <h1>한번에 등록,<br></br>한방에 구매</h1></div>
            {isDesktopOrMobile !== true ?
              <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={match01}></source></video></div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={match02}></source></video></div>
              </div>
            : 
               <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={matchmobile}></source></video></div>
              </div>
            }
            <div className="sub-text"><p>복잡하게 알아볼 필요 없어요!<br></br>냉장고, 세탁기, 에어컨 등등<br></br>여러 상품이 필요해도 한번에!</p></div>
          </div>
        </div>
      </section>
      <section className="info-app-pickus" id="about">
        <div className="content-components">
          <div className="content-inner ai-column">
            <div className="text"><h2>AI 시세 조회</h2>
            <h1>내 가전 시세가 궁금하시다구요?<br></br>그럼 한번 찍어봐요!</h1></div>
            {isDesktopOrMobile !== true ?
              <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={ai}></source></video></div>
              </div>
            : 
               <div>
                <div className="phone" data-aos={dataAos} data-aos-duration={dataAosDuration} data-aos-offset={dataAosOffset}><img src={phone}></img><video
          autoPlay muted playsInline
          ><source src={ai}></source></video></div>
              </div>
            }
            <div className="ai-text"><p>제품 정보표와 에너지 등급표를 찍으면<br></br>AI가 자동으로 내 가전의 현재 시세를 계산해줘요!</p></div>
            
          </div>
        </div>
      </section>
      <section className="info-app-pickus" id="about">
        <div className="content-components">
          <div className="content-inner">
          <h1>피커스 소개영상</h1>
        <video
          autoPlay loop muted playsInline controls
          ><source src={pickusvideo}></source></video>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Service;
