import React from "react";

function Partner() {
  return (
    <div className="partner">
      <h1>partner</h1>
    </div>
  );
}

export default Partner;
