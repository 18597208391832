import React from "react";

function contect() {
  return (
    <div className="contect">
      <h1>contect</h1>
    </div>
  );
}

export default contect;
