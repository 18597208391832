import React from "react";
import { useMediaQuery } from 'react-responsive'
import "./Home.css";
import { dehuvcontantsData } from "../components/data/dehuvcontantsData";
import Card from "../components/DehuvinfoCard";
import dehuvvideo from "../assets/dehuv.mp4";
import dehuvback from "../assets/dehuv-back.png";

function Home() {
  const isDesktopOrMobile = useMediaQuery({query: '(max-width:1320px)'});
  return (
    <div className="home" id="home">
      <section className="showcase content-components">
        <video
          src={dehuvvideo}
          autoPlay loop muted playsInline
        ></video>
        <div className="vidText content-inner">
          <h3>Design Human Value</h3>
          <h2>DEHUV</h2>
        </div>
        <div className="overlay"></div>
        <div className="arrow-container">
          <div className="arrow-down"></div>
        </div>
      </section>
      <section className="intro-dehuv content-components">
        <div className="content-inner">
        <h2>디휴브는 새로운 가치를 찾고,<br></br>지속가능한 미래를 위해 노력합니다.</h2>
        <div className="earth"><img src={dehuvback}></img></div>
          <div className="shame-dehuv">
            <div className="shame-column">
              <h3>대형가전 재활용 요청 수</h3>
              <h1>14,360건 +</h1>
            </div>
            <div className="shame-column">
              <h3>월 방문자 수</h3>
              <h1>32,588명 +</h1>
            </div>
            <div className="shame-column">
              <h3>전체회원 수</h3>
              <h1>11,588명 +</h1>
            </div>
            <div className="shame-column">
              <h3>파트너센터 수</h3>
              <h1>581개 +</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="intro-dehuv content-components">
        <div className="content-inner">
        <div className="card-title">BRAND VALUE</div>
          <div className="value-dehuv"> 
            <div className="value-column">
              <h1>🤙</h1>
              <h1>신뢰</h1>
              <h3>믿을수 있는</h3>
            </div>
            <div className="value-column">
              <h1>🙆‍♀️</h1>
              <h1>친절</h1>
              <h3>어떠한 환경에서도</h3>
            </div>
            <div className="value-column">
              <h1>💡</h1>
              <h1>창의성</h1>
              <h3>세상을 바꾸는</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="intro-dehuv content-components">
        <div className="content-inner">
          {isDesktopOrMobile !== true ?
              <div>
                <div className="card-title">디휴브가 새로운 가치를 만드는 방법</div>
              </div>
            : 
               <div>
                <div className="card-title">디휴브가 새로운 가치를<br></br>만드는 방법</div>
              </div>
          }
          <Card
          data={dehuvcontantsData}
          className="dehuv-card"
          caid="10"
          />
        </div>
      </section>
      <section className="intro-dehuv content-components">
        <div className="content-inner">
        <div className="card-title">디휴브 연혁</div>
        <div className="timetop">
          <div className="timecontainer">
            <div className="timeline">
              <ul>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2022</h3>
                    <h1>삼성 닷컴 중고보상 프로그램 협약</h1>
                    <p>LG CNS 라이프 케어 협약<br></br>
                    빅데이터 활용 지원사업<br></br>
                    고양 ICT 기술 개발 지원사업<br></br>
                    지역특화육성 지원사업<br></br>
                    스마트 서비스 지원사업<br></br>
                    AI+콘텐츠 지원사업</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2021</h3>
                    <h1>고양 ICT 기술 개발 지원사업</h1>
                    <p>빅데이터 활용 지원사업<br></br>
                    중소벤처기업부 창업성장기술개발 R&D</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2020</h3>
                    <h1>데이터 바우처 지원사업</h1>
                    <p>고양 ICT 마케팅 지원사업<br></br>
                    디자인 솔루션 랩 프로젝트<br></br>
                    기술 닥터 지원사업</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2019</h3>
                    <h1>창업도약 패키지 IT콘텐츠 시장최적화 프로그램</h1>
                    <p>특허 등록 "재활용 물품 거래 시스템과 방법 및 이를 위한 컴퓨터 프로그램"<br></br>
                    R&D 플러스 파트너 지원프로그램<br></br>
                    청년 프런티어 지원사업</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2018</h3>
                    <h1>창업성공지원프로그램</h1>
                    <p>창업도약 패키지 성장촉진 프로그램<br></br>
                    중소기업 R&D 기획지원사업 전략과제<br></br>
                    자인 솔루션 랩 프로젝트</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2017</h3>
                    <h1>청년창업 smart2030 </h1>
                    <p>G창업 프로젝트<br></br>
                    재도전 성공패키지 지원사업 <br></br>
                    앱쇼 코리아 전시회 참가</p>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <h3 className="date">2016</h3>
                    <h1>환경부 ICT 공모전 산업기술원장상 수상</h1>
                    <p>경기중소기업종합지원센터 이사상 수상<br></br>
                    청년창업 smart2030<br></br>
                    G창업 프로젝트<br></br>
                    창의도전형 SW개발 지원사업</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
