import React from "react";

function Page2() {
  return (
    <div className="isedol">
      <h1>Page2</h1>
    </div>
  );
}

export default Page2;
