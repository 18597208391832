function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/card", false, /\.(png|jpe?g|svg)$/)
);
/*const content = importAll(
  require.context("../../assets/card/content", false, /\.(mp4|png|jpe?g|svg)$/)
);*/
const videos = importAll(
  require.context("../../assets", false)
);

export const dehuvcontantsData = [
  {id:1, caid:10, title: "중고 가전 ∙ 가구 O2O 중개 플랫폼",
    text: "우리동네 재활용센터 총집합! 중고∙ 가구 거래 중개 플랫폼",  tag: "#중고가전 #재활용센터 #중고거래 #철거원복", image:images["card-pickus.jpg"].default, video:videos["pickus_video.mp4"].default},

  {id:2, caid:10, title: "딥러닝 기술을 활용한 시세 분석 시스템",
    text: "사진 한장으로 모델명, 연식부터 현재 시세까지 자동으로 분석!",  tag: "#AI #딥러닝 #빅데이터", image:images["card-deep.jpg"].default, video:videos["pickus_video.mp4"].default},

  {id:3, caid:10, title: "소비자와 재활용센터의 거리 기반 매칭 시스템",
    text: "현재 내 위치를 기준으로 중고가전 처리를 원하는 고객을 표시",  tag: "#중고가전 #재활용센터 #중고거래 #지도", image:images["card-map.jpg"].default, video:videos["pickus_video.mp4"].default}, 

  {id:4, caid:10, title: "친환경 공급망 형성을 위한 지속적인 파트너십 형성",
    text: "꼭 필요한 시장인 중고가전 거래시장을 활성화 하기 위한 지속적인 파트너십",  tag: "#피커스파트너 #파트너센터 #재활용센터 #협업", image:images["card-partner.jpg"].default, video:videos["pickus_video.mp4"].default}, 
];