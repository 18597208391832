import React, { Component } from 'react';
import "./DehuvinfoCard.css";
import Modal from "./DehuvinfoModal";


class Card extends Component {

  state = {
    showModal: 0
  };

  getModal = value => {
    this.setState({ showModal: value });
  };

  hideModal = value => {
    setTimeout(() => {
      this.setState({ showModal: 0 });
    }, 200) 
  };

  render() {
    
    return (
      <div className="container">
        <div className="sct">
        {this.props.data.filter(category => category.caid == this.props.caid).map((data, key) => (
          <div key={key} className="dehuv-card">
            <div onClick={() => this.getModal(data.id)}>
              <div className='card-list'>
                <div className='card-text'>
                  <h2>{data.title}</h2>
                  <h3>{data.text}</h3>
                  <p>{data.tag}</p>
                </div>
                <div className='card-img'>
                  <img src={data.image}/>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.showModal === data.id}
              onHide={() => this.hideModal(data.id)}
              content={data.content}
              video={data.video}
              modalclass="modal-open"
            />
          </div>
        ))}
       
        </div>
      </div>
      
    );
  }
}

export default Card;